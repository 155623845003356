const welcomeToJuice = "Welcome to the Juice!";
const termsOfUse = "Terms of Use";

/* --- */

const close = "Close";
const data = "Data";
const accounts = "Accounts";
const inProcess = "In Process...";
const dailyEmails = "Daily Emails";
const loadMore = "Load more";
const readMore = "Read more";

/* --- */

const tryAgain = "Try again";
const error = "Something went wrong.";
const errorTryAgain = "Something went wrong. Please try again.";

const contactSupport = "Contact support";

const emailSentSuccess = "Email successfully sent!";
const emailUpdatedSuccess = "Email successfully updated!";
const passwordUpdated = "Password successfully updated!";
const passwordChanged = "Password successfully changed!";
const fullNameChanged = "Name successfully changed!";
const emailNotificationsEnabled = "Email notifications successfully enabled!";
const emailNotificationsDisabled = "Email notifications successfully disabled!";
const autoRenewEnabled = "Auto-Renew Plan successfully enabled!";
const autoRenewDisabled = "Auto-Renew Plan successfully disabled!";

const studentResetPassword = `
The student's password for The Juice was successfully reset.
Since they do not have an email address on record, make sure to tell them their new password.
`;

const studentInAnotherLMSClass = `
This student is in another class in your learning management system and cannot be added to this class.
`;

const categoryFilter = "Category Filters";
const selectCategories = "Select one or more categories to filter search content";

const noStudentAnswers = "The student has not yet answered any questions in this issue of the Daily Juice.";
const noStudentDailyJuices = "The student has no Daily Juices.";

const noJuicesInRange = "No Daily Juices available in the selected date range";
const noAssignmentsInRange = "No Assignments found in the selected date range";

const noQuizResults = `
Looks like we don't have enough data to show you quiz results yet.
Check back again soon!
`;

const noData = "No data found";
const noExtraJuices = "No Extra Juices found.";
const noStandards = "No Standards found.";
const noVideos = "No videos found.";
const noClosedPolls = "Closed polls not found. Please check again later.";
const noAssignments = "No assignments have been completed by the student.";
const noDataBySchools = "No data by schools found.";
const noClassesWithStudents = "No classes with students found";
const noAssignmentsFound = "No assignments found.";
const noTeachersFound = "No Teachers found.";
const noStoriesAssigned = "No stories assigned";
const noQuestionsAnswered = "No questions answered";
const noClassesFoundAndCannotBeAdded = "No classes found and no classes can be added.";
const noClassesFoundCreateNew = "No classes found, please create a new class.";
const noClassesFoundPleaseSync = "No classes found. Please sync classes.";
const noDepartmentsForSelectedSchool = "No Departments found for selected class";
const noPdfAvailable = "PDF file is not available. Please try again.";

const selectedUserRecommendations = "Make Selected User Recommendations";

/* --- */

const selectDistrict = "Please select district";
const selectSchool = "Please select school";
const selectDepartment = "Please select department";
const selectClass = "Please select class";

/* --- */

const assignmentCompleted = "All assignments completed!";
const assignmentCreated = "This assignment was successfully created!";
const assignmentCopied = "The link to assignment has been copied to your clipboard.";

/* --- */

const alreadySigned = `
Hmm. You are already signed in to The Juice so we cannot create a new account for you.
`;

/* --- */

const trialRegistration1 = `
Educator Sign Up
`;

const trialRegistration2 = `
Educators, let's get you set up! What's your role?
`;

const trialRegistration3 = `
Students can not sign up here.
Your teacher can provide you instructions for creating an account.
If you already have an account, sign in
`;

const trialRegistration4 = `
We are pleased to offer you a free trial to all of the Juice student and teacher materials.
`;

const trialRegistration6 = `
Let's get you set up!
`;

const trialCreateAccount1 = `
Once created, your account will enable you to add students under your master account, see their engagement with the product, assess their performance on key reading standards, and create your own assignments.
`;

const trialCreateAccount2 = `
Once created, your account will enable you to add children under your master account, see their engagement with the product, assess their performance on key reading standards, and create your own assignments.
`;

const purchaseSubscription1 = `
Thank you for purchasing a subscription.
To complete your subscription, we need to gather information about you and your initial class, then collect payment information.
When you complete the process, you will be ready to start with your new Juice account.
`;

const purchaseSubscription2 = `
If you don't complete the process or get interrupted - don't worry, when you come back we will complete the signup process!
`;

/* --- */

const userHasNoPaymentPlans = `
For plan details, please contact your system administrator or customer support
`;

/* --- */

const restorePasswordStudent = `
We let your teacher know that you need a new password.
They're probably great and will help you out as soon as they're able to do so.
Until you're back in The Juice, check out some of the fresh stories on our news aggregator.
`;

const restorePasswordViaEmail = `
Huzzah! A fresh reset link is on its way! Check your email.
`;

const restorePasswordLMSUser = `
Your account is managed by your school's LMS system.
Please contact your LMS administrator for password reset help.
`;

/* --- */

const teacherAdministratorSchoolDetails = `
On this panel, you will provide your country, state, school and district information.
For the School District field, please indicate the school district you work for, <strong>or overwrite the field with N/A.</strong>
If you are not part of a school, <strong>overwrite the School Name field with N/A.</strong>
`;

const teacherOrganizationSchoolDetails = `
On this panel, you will provide your country, state, school and district information.
For the School District field, please overwrite the field with <strong>your company or organization name.</strong>
For the School field, overwrite the field with your <strong>job title or N/A.</strong>
`;

const teacherAdministratorClassSetup1 = `
Your free trial of The Juice provides you with immediate access to evaluate all of the tools in the teacher portal.
To complete the Department field, <strong>use one of the drop-down menu options, type in your own custom department name, or type in N/A.</strong>
You will create your own class name. For reading level, pick an option from the drop-down menu.
You can adjust this later on in the teacher portal.
`;

const teacherAdministratorClassSetup2 = `
To evaluate the student material, you will need to go into the class you have just created and then add yourself as a student.
You will need to use a different email to set yourself up as a student as our system only allows an email to be assigned to a single account.
`;

const teacherOrganizationClassSetup1 = `
Your free trial of The Juice provides you with immediate access to evaluate all of the tools in the teacher portal.
To complete the Department field, <strong>use one of the drop-down menu options, type in your own custom department name, or type in N/A.</strong>
You will create your own class name. For reading level, pick an option from the drop-down menu. You can adjust this later on in the teacher portal.
`;

const teacherOrganizationClassSetup2 = `
To evaluate the student material, you will need to go into the class you have just created and then add yourself as a student.
You will need to use a different email to set yourself up as a student as our system only allows an email to be assigned to a single account.
`;

/* --- */

const teacherSignUpForm1 = `
We have pre-populated this form with schools and districts across the USA.
If your location is not on the list, or you are from another country,
you can fill in your own school and/or district in the associated text box.
`;

const teacherSignUpForm3 = `
Please provide your general location so we can better tailor The Juice for you.
`;

const teacherSignUpForm4 = `
Almost done, set up your first class here.
`;

const teacherSignUpForm6 = `
We ask for this information to help set up your homeschool class and
establish the default reading level so we know what reading level to provide your students.
`;

const teacherSignUpForm7 = `
You will soon receive an email confirming your account.
It will provide additional information about how to make the most of your Juice trial.
If it does not appear in your in-box, please check your spam folder and/or check with your tech team to ensure emails from thejuice.news are permitted.
`;

const teacherSignUpForm8 = `
You will soon receive an email confirming your account.
It will provide additional information about how to make the most of your Juice trial.
If it does not appear in your in-box, please check your spam folder.
`;

const teacherSignUpForm9 = `
And, each morning you will receive a short email that summarizes the stories in that day's Juice with a link to go experience all the features of The Juice.
`;

/* --- */

const shareClassLink = "Share the link with your students.";

/* --- */

const teacherByEmailNotFound = "The teacher with this email address was not found in the system. Please enter teacher first and last name.";

/* --- */

const inactiveStudent = `
Hmmm. Your account appears to have been removed from the class.
Please contact your teacher or customer support at <a href="mailto:customersupport@thejuice.news">customersupport@thejuice.news</a>
`;

const inactiveTeacher = `
Hmmm. Your account appears to be inactive.
Please contact customer support at <a href="mailto:customersupport@thejuice.news">customersupport@thejuice.news</a>
`;

/* --- */

const classesEndDateDescription1 = `
If you need to save student records, please go to the Class tab and select the download icon (top right) and select download all data.
`;

const classesEndDateDescription2 = `
When the class ends, all the students will be removed from this class.
`;

const classesEndDateDescription3 = `
If you have any questions or concerns, please contact <a target="_blank" href="//thejuicelearning.com/support/">customer support</a>.
`;

/* --- */

const automaticThemeDescription = `
Automatically switch between light and dark modes based on your system settings.
`;

/* --- */

const classTableDescription = "Correct answers in completed Daily Juices";

/* --- */

const blockProgressError = `
We can't load the graphics, the data is not correct.
Please try again later or contact support.
`;

const chartRadarError = `
We can't load the graphics, the data is not correct.
Please try again later or contact support.
`;

/* --- */

const usersInvalidEmail = `
Emails to one or more users are not working properly.
Please check user emails.
`;

const userInvalidEmail = `
Emails to this user are not working - please update email address.
`;

/* --- */

const confirmLMSClassRemovalTitle = "This operation will remove classes from your juice account.";

const confirmLMSClassRemoval = `
Are you sure you want to proceed?
Press Cancel or OK to proceed.
`;


/* --- */

const issueNotAvailable = "Issue not available";
const cantLoadJuice = "Oops. We can't load Daily Juice";

/* --- */

const thanksForSubscribing = "Thank you for subscribing to The Juice!";
const updateYourClasses = "We need to make some updates to your classes";
const backToTeacherPortal = "Back to the teacher portal";
const paymentFailed = `
We are sorry but the payment failed!
Please try again or contact support.
`;

/* --- */

const LMSClassCannotHaveEndDate = "An LMS class cannot have an end date";
const backToPortal = "Back to teacher portal";

/* --- */

const signUpViaLMS = "Sign in with your School's LMS Credentials";
const contactHere = "Need help? Contact us here";
const tooManyTechnicalIssues = "I'm having too many technical issues.";
const productDoesNotWorkAsExpected = "The product doesn't work as expected.";
const tooComplicated = "The product and features are too complicated for me.";
const productForASpecificDuration = "I needed the product for a specific duration only.";
const notUsingEnough = "I'm not using the product often enough.";
const contentDidNotLiveUpToExpectations = "The content did not live up to expectations.";
const cancelFreeTrialDescription = "I would like to cancel my Free trial, remove my account and the accounts of all of my students immediately.";
const cancelPaidSubscription = "I would like to cancel my subscription, stop any future payments and remove my account and the accounts of all of my students.";
const cancelSubscriptionDetails = "I would like to cancel my subscription, stop any future payments and remove my account and the accounts of all of my students.";
const cancellationHasAlreadyMade = "The cancellation request has already been made.";
const additionalReason = "Use this space to share additional reasons that are not included above";

const selectOptionBelow = "Please select one of the options below";
const cancelPreference = "Cancelation Preference";
const cancellationDetails = "Cancellation Details";
const loseAccessAndData = "You will lose access to all student performance and learning standards data.";
const cancellationDate = "Cancellation Date";
const accessEndImmediately = "Access to your plan will end immediately.";
const noLongerAccess = "You will no longer have access to your teacher/administrator account and your students will be unable to access their accounts.";
const noLongerDailyEmails = "You will no longer receive any daily emails from The Juice starting tomorrow.";
const cancelSubscription = "Cancel Subscription";
const cancelFreeTrial = "Cancel Free Trial";
const affectedPlan = "Affected Plan";
const freeTrial = "The Juice Free Trial";
const planCanceled = "Your plan was canceled";
const freeTrialCanceled = "Your Free Trial was canceled";
const checkEmailForDetails = "Check your email for more details";
const selectThatApply = "Select all that apply";
const whyYouWantToCancel = "Why do you want to cancel?";
const weAreSorryToSeeYouGo = "We are sorry to see you go";

/* --- */

const paymentDetails = "Payment Details";
const addPaymentMethod = "Add a Payment Method";
const paymentConfirmation = "Payment Confirmation";
const confirmAndPay = "Confirm and Pay";
const selectAPlan = "Select a Plan";
const yourAccount = "Your Account";
const avatarDeleted = "Your avatar was successfully deleted";

/* --- */

const deleteLastClassInfo = "By removing this class, the teacher will be marked inactive and will not have access to The Juice until they are added to another class.";
const areYouSureDeleteThisClass = "Are you sure you want to delete this class?";

/* --- */

const accountExpired = "Nooooo! Your account has expired!";
const studentExpired = `
Don't worry, your data has been saved and will always remain secure.
Tell your teacher to subscribe so you can keep reading The Juice!
`;

/* --- */

const notifications = "Notifications";
const noNotifications = "No notifications available";

/* --- */

const lmsDomainDetected = "Users in this domain can only access The Juice when they are authorized by the district's LMS system and logon via our LMS Sign In access.";
const tryAnotherEmail = "You can try again using a different email email domain.";
const signInIfYouHaveAccount = "If you have an LMS account already, you can sign in here.";
const contactUsInCaseSignInTroubles = "If you need additional help with signing up or logging in, please reach out ";
const contactUsInCaseSignInIssuesViaLMS = "If you are having trouble logging in with your LMS credentials, please contact customer support ";

/* --- */

const allReadingLevelsAssignedToYourClass = "All reading levels assigned to your class";

/* --- */

export default {
    welcomeToJuice,
    termsOfUse,

    close,
    data,
    accounts,
    inProcess,
    dailyEmails,
    loadMore,
    readMore,

    error,
    errorTryAgain,

    tryAgain,

    contactSupport,

    emailSentSuccess,
    emailUpdatedSuccess,
    passwordUpdated,
    passwordChanged,
    fullNameChanged,
    emailNotificationsEnabled,
    emailNotificationsDisabled,
    autoRenewEnabled,
    autoRenewDisabled,

    studentResetPassword,
    studentInAnotherLMSClass,

    selectCategories,
    categoryFilter,

    noData,
    noStudentAnswers,
    noStudentDailyJuices,
    noJuicesInRange,
    noAssignmentsInRange,
    noQuizResults,
    noExtraJuices,
    noVideos,
    noClosedPolls,
    noStandards,
    noAssignments,
    noDataBySchools,
    noClassesWithStudents,
    noAssignmentsFound,
    noTeachersFound,
    noStoriesAssigned,
    noQuestionsAnswered,
    noClassesFoundAndCannotBeAdded,
    noClassesFoundCreateNew,
    noClassesFoundPleaseSync,
    noDepartmentsForSelectedSchool,
    noPdfAvailable,

    selectedUserRecommendations,

    selectDistrict,
    selectSchool,
    selectDepartment,
    selectClass,

    assignmentCompleted,
    assignmentCreated,
    assignmentCopied,

    alreadySigned,

    trialRegistration1,
    trialRegistration2,
    trialRegistration3,
    trialRegistration4,
    trialRegistration6,

    trialCreateAccount1,
    trialCreateAccount2,

    purchaseSubscription1,
    purchaseSubscription2,

    userHasNoPaymentPlans,

    restorePasswordStudent,
    restorePasswordViaEmail,
    restorePasswordLMSUser,

    teacherAdministratorSchoolDetails,
    teacherOrganizationSchoolDetails,

    teacherAdministratorClassSetup1,
    teacherAdministratorClassSetup2,
    teacherOrganizationClassSetup1,
    teacherOrganizationClassSetup2,

    teacherSignUpForm1,
    teacherSignUpForm3,
    teacherSignUpForm4,
    teacherSignUpForm6,
    teacherSignUpForm7,
    teacherSignUpForm8,
    teacherSignUpForm9,

    shareClassLink,

    teacherByEmailNotFound,

    inactiveStudent,
    inactiveTeacher,

    classesEndDateDescription1,
    classesEndDateDescription2,
    classesEndDateDescription3,

    automaticThemeDescription,
    classTableDescription,

    blockProgressError,
    chartRadarError,

    usersInvalidEmail,
    userInvalidEmail,

    confirmLMSClassRemovalTitle,
    confirmLMSClassRemoval,

    issueNotAvailable,
    cantLoadJuice,

    thanksForSubscribing,
    updateYourClasses,
    backToTeacherPortal,
    paymentFailed,

    LMSClassCannotHaveEndDate,
    backToPortal,

    signUpViaLMS,
    contactHere,
    tooManyTechnicalIssues,
    productDoesNotWorkAsExpected,
    tooComplicated,
    productForASpecificDuration,
    notUsingEnough,
    contentDidNotLiveUpToExpectations,
    cancelFreeTrialDescription,
    cancelPaidSubscription,
    cancelSubscriptionDetails,
    cancellationHasAlreadyMade,
    additionalReason,

    selectOptionBelow,
    cancelPreference,
    cancellationDetails,
    loseAccessAndData,
    cancellationDate,
    accessEndImmediately,
    noLongerAccess,
    noLongerDailyEmails,
    cancelSubscription,
    cancelFreeTrial,
    affectedPlan,
    freeTrial,
    planCanceled,
    freeTrialCanceled,
    checkEmailForDetails,
    selectThatApply,
    whyYouWantToCancel,
    weAreSorryToSeeYouGo,

    paymentDetails,
    addPaymentMethod,
    paymentConfirmation,
    confirmAndPay,
    selectAPlan,
    yourAccount,
    avatarDeleted,

    deleteLastClassInfo,
    areYouSureDeleteThisClass,

    accountExpired,
    studentExpired,

    notifications,
    noNotifications,

    lmsDomainDetected,
    tryAnotherEmail,
    signInIfYouHaveAccount,
    contactUsInCaseSignInTroubles,
    contactUsInCaseSignInIssuesViaLMS,
    allReadingLevelsAssignedToYourClass,
};
