import text from "@/base/text/index.js";


const loadJuicesDatesByGrades = (services, params = {}) => {
    const { api, actions } = services;
    const { grades } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuicesDatesByGradesLoading({
            grades,
        }));

        const res = await api.dailyJuices.getJuicesDatesByGrade({
            session: state.user.session,
            grade: grades,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.juices || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuicesDatesByGrades({
            grades,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

const loadOnceJuicesDatesByGrades = (services, params = {}) => {
    const { grades } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const data = state.juices.juicesDatesByGrades[grades] || {};

        if (data.isLoaded && !data.error) {
            return {
                data: data.data,
            };
        }

        const res = await dispatch(loadJuicesDatesByGrades(services, params));
        return res;
    };
};

export default {
    loadJuicesDatesByGrades,
    loadOnceJuicesDatesByGrades,
};
