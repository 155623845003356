export default (apiURL, methods) => {
    return {
        sendEvent(params = {}) {
            const url = `${apiURL}/events`;

            let { timeTook } = params;

            if (timeTook === undefined) {
                timeTook = -1;
            }

            const eventParams = {
                session_id: params.session,
                location_id: params.locationId,
                event_id: params.eventId,
                object_id: params.objectId,
                sub_object_id: params.subObjectId,
                sub_sub_object_id: params.subSubObjectId,
                time_took: timeTook,
                userId: params.user.userId,
                username: params.user.userName,
                userEmail: params.user.email,
                userType: params.user.userType,
                userRole: params.user.roles[0],
                userSchool: params.user.school,
                userDepartment: params.user.department,
                userDistrictId: params.user.districtId,
                userDistrict: params.user.district,
                userCountry: params.user.country,
                userSubdivision: params.user.subdivision,
            };

            if (params.objectIdString) {
                eventParams.object_id_string = params.objectIdString;
            }

            return methods.post(url, eventParams);
        },
    };
};
