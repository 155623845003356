import Standards from "@/base/project/standards.js";

import array from "@/base/lib/array.js";


const SORT_BY = {
    dateAssignedNewestFirst: "date assigned: newest first",
    dateAssignedOldestFirst: "date assigned: oldest first",

    dateCompletedNewestFirst: "date completed: newest first",
    dateCompletedOldestFirst: "date completed: oldest first",

    gradeHighToLow: "grade: high to low",
    gradeLowToHigh: "grade: low to high",

    readingLevelHighToLow: "reading level: high to low",
    readingLevelLowToHigh: "reading level: low to high",

    standardHighToLow: "standard: high to low",
    standardLowToHigh: "standard: low to high",
};

const STATUSES = {
    correct: "correct",
    incorrect: "incorrect",
    pending: "pending",
    processingAnswer: "processingAnswer",
};

/* --- */

const isCorrectAssignmentStatus = (status) => {
    return status === STATUSES.correct;
};

const isIncorrectAssignmentStatus = (status) => {
    return status === STATUSES.incorrect;
};

const isPendingAssignmentStatus = (status) => {
    return status === STATUSES.pending;
};

const isProcessingAnswerAssignmentStatus = (status) => {
    return status === STATUSES.processingAnswer;
};

/* --- */

const getCorrectAssignmentStatus = () => {
    return STATUSES.correct;
};

const getIncorrectAssignmentStatus = () => {
    return STATUSES.incorrect;
};

const getPendingAssignmentStatus = () => {
    return STATUSES.pending;
};

const getAssignmentsStatuses = () => {
    const keys = Object.keys(STATUSES);

    const statuses = [];

    for (let i = 0; i < keys.length; i += 1) {
        const status = STATUSES[keys[i]];

        statuses.push(status);
    }

    return statuses;
};

/* --- */

const getSortValues = () => {
    return { ...SORT_BY };
};

/* --- */

const sortByDate = (data, value, fieldName) => {
    const dataWithoutDates = [];
    const dataWithDates = [];

    for (let i = 0; i < data.length; i += 1) {
        const d = data[i];

        if (d[fieldName]) {
            dataWithDates.push(d);
        } else {
            dataWithoutDates.push(d);
        }
    }

    if (value === SORT_BY.dateAssignedNewestFirst
        || value === SORT_BY.dateCompletedNewestFirst) {
        const sortedByDate = array.sortByDateASC(dataWithDates, fieldName);

        return [
            ...dataWithoutDates,
            ...sortedByDate,
        ];
    }

    const sortedByDate = array.sortByDateDESC(dataWithDates, fieldName);

    return [
        ...sortedByDate,
        ...dataWithoutDates,
    ];
};

const sortByGrade = (data, value) => {
    const sorted = [...data].sort((a, b) => {
        const s1 = a.score;
        const s2 = b.score;

        if (value === SORT_BY.gradeHighToLow) {
            return s1 - s2;
        }

        return s2 - s1;
    });

    return sorted;
};

const sortByReadingLevel = (data, value) => {
    let gradesOrder = [["G5", "G6"], ["G7", "G8"], ["G9", "G10"], ["G11", "G12"]];

    if (value === SORT_BY.readingLevelHighToLow) {
        gradesOrder = [...gradesOrder].reverse();
    }

    const sorted = [];
    const usedIndexes = [];

    for (let i = 0; i < gradesOrder.length; i += 1) {
        const grades = gradesOrder[i];

        for (let j = 0; j < data.length; j += 1) {
            const d = data[j];
            const grade = d.grades || [];

            for (let k = 0; k < grade.length; k += 1) {
                const g = grade[k];

                if (grades.indexOf(g) !== -1 && usedIndexes.indexOf(j) === -1) {
                    sorted.push(d);
                    usedIndexes.push(j);
                    break;
                }
            }
        }
    }

    return sorted;
};

const sortByStandards = (data, value) => {
    const sorted = [...data].sort((a, b) => {
        const aJuiceStandard = Standards.getJuiceStandard(a.standards)?.[0]?.standard || null;
        const bJuiceStandard = Standards.getJuiceStandard(b.standards)?.[0]?.standard || null;

        let s1 = 0;
        let s2 = 0;

        if (aJuiceStandard) {
            s1 = parseInt(aJuiceStandard.split(".")[2], 10) || 0;
        }

        if (bJuiceStandard) {
            s2 = parseInt(bJuiceStandard.split(".")[2], 10) || 0;
        }

        if (value === SORT_BY.standardHighToLow) {
            return s1 - s2;
        }

        return s2 - s1;
    });

    return sorted;
};

const sort = (data, value) => {
    if (value === SORT_BY.dateAssignedNewestFirst
        || value === SORT_BY.dateAssignedOldestFirst) {
        return sortByDate(data, value, "dateAssigned");
    }

    if (value === SORT_BY.dateCompletedNewestFirst
        || value === SORT_BY.dateCompletedOldestFirst) {
        return sortByDate(data, value, "dateCompleted");
    }

    if (value === SORT_BY.gradeHighToLow
        || value === SORT_BY.gradeLowToHigh) {
        return sortByGrade(data, value);
    }

    if (value === SORT_BY.readingLevelHighToLow
        || value === SORT_BY.readingLevelLowToHigh) {
        return sortByReadingLevel(data, value);
    }

    if (value === SORT_BY.standardHighToLow
        || value === SORT_BY.standardLowToHigh) {
        return sortByStandards(data, value);
    }

    return data;
};

/* --- */

const getStoryById = (stories, id) => {
    for (let i = 0; i < stories.length; i += 1) {
        const story = stories[i];

        if (story.ID === id) {
            return story;
        }
    }

    return null;
};

/* --- */

export default {
    isCorrectAssignmentStatus,
    isIncorrectAssignmentStatus,
    isPendingAssignmentStatus,
    isProcessingAnswerAssignmentStatus,

    getCorrectAssignmentStatus,
    getIncorrectAssignmentStatus,
    getPendingAssignmentStatus,
    getAssignmentsStatuses,

    getSortValues,

    sort,

    getStoryById,
};
