import text from "@/base/text/index.js";


const loadAssignmentStories = (services, params) => {
    const { actions, api } = services;
    const { groupCode } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const session = state.user.session;

        dispatch(actions.assignments.setAssignmentByGroupCodeLoading({
            groupCode,
        }));

        const res = await api.assignments.getAssignmentStories({
            session,
            groupCode,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.assignments.setAssignmentByGroupCode({
            groupCode,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

export default {
    loadAssignmentStories,
};
