export default (apiURL, methods) => {
    return {
        // TODO: remove later
        createAssignment(params = {}) {
            // NOTE: action=assignments_create_by_story
            const url = `${apiURL}/assignments/create/by-story `;

            return methods.post(url, {
                session_id: params.session,
                quiz_id: params.quizId,
                question_id: params.questionId,
                story_id: params.storyId,
                students_ids: params.studentsIds,
            });
        },

        setQuizAnswer(params = {}) {
            // NOTE: action=assignments_set_quiz_answer
            const url = `${apiURL}/assignments/quizzes/${params.quizId}/${params.answerId}`;

            return methods.post(url, {
                session_id: params.session,
                quiz_id: params.quizId,
                answer_id: params.answerId,
                assignment_id: params.assignmentId,
                assignment_content_id: params.assignmentContentId,
                question_id: params.questionId,
                time_took: params.timeTook || 0,
            });
        },

        getAssignmentStories(params = {}) {
            // NOTE: action=assignments_get_stories_by_code
            const url = `${apiURL}/assignments/stories/by-code`;

            // NOTE: delete "code" from rest api
            return methods.get3(url, {
                session_id: params.session,
                group_code: params.groupCode,
            });
        },
    };
};
